<template>
  <div>
    <div class="backsOut">
      <div class="backs">
        <img src="@/assets/images/fh.png" alt="" @click="goback" />
        <span>实名认证</span>
      </div>
    </div>
    <div>
      <van-notice-bar
        left-icon="volume-o"
        text="注意：填写的手机号必须是银行卡的预留手机号"
      />
    </div>
    <div>
      <van-field
        v-model="userData.name"
        :disabled="disabled"
        label="真实姓名"
      />
      <van-field
        v-model="userData.identity"
        :disabled="disabled"
        label="身份证"
      />
      <van-field
        v-model="userData.bankCard"
        :disabled="disabled"
        label="银行卡"
      />
      <van-field
        v-model="userData.openingBank"
        @click="openBlank"
        :disabled="disabled"
        label="开户行"
      />

      <van-picker
        title="请选择开户行"
        show-toolbar
        class="ingudn"
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
        v-if="flags"
      />
      <van-field
        :disabled="disabled"
        v-model="userData.telephone"
        label="手机号"
      />
      <p></p>
    </div>
    <van-button
      class="btn"
      :disabled="disabled"
      type="primary"
      size="large"
      @click="register"
      >{{ btntext }}</van-button
    >
    <div class="wefx" v-if="maskLayer"></div>
  </div>
</template>

<script>
import {
  realAuth,
  getBankList,
  getRealAuth,
  getFaceIdentityUrl,
} from "@/request/api";
export default {
  data() {
    return {
      userData: {
        // name: "喻勇",
        // identity: "411524199511116811",
        // bankCard: "6217002430030287644",
        // openingBank: "建设银行",
        // telephone: "15537683878",
        // openBankId: "2",
        name: "",
        identity: "",
        bankCard: "",
        openingBank: "",
        telephone: "",
        openBankId: "",
      },
      linbankId: "",
      disabled: false,
      flags: false,
      columns: [],
      btntext: "",
      maskLayer: false,
      clidsjs: false,
      openBankList: "",
    };
  },
  created() {
    this.getRA();
  },
  methods: {
    // 获取银行卡的列表
    getBankList() {
      getBankList().then((res) => {
        if (res.code == "200") {
          this.openBankList = res.data;
          res.data.forEach((req) => {
            // console.log(req);
            // console.log(this.linbankId);
            if (req.id == this.linbankId) {
              // console.log(req.id);
              this.userData.openingBank = req.bankName;
            }
            this.columns.push(req.bankName);
          });
        }
      });
    },
    //实名信息
    getRA() {
      getRealAuth().then((res) => {
        console.log(res);
        this.getBankList();
        if (res.code == "200") {
          this.userData.name = res.data.name;
          this.userData.identity = res.data.idCard;
          this.userData.bankCard = res.data.bankCard;
          this.linbankId = res.data.bankId;

          this.userData.telephone = res.data.mobile;
          this.disabled = true;
          this.flags = false;
          this.clidsjs = false;
          this.btntext = "已认证";
        } else {
          this.clidsjs = true;
          this.btntext = "立即认证";
        }
      });
    },

    onConfirm(value, index) {
      this.flags = false;
      this.maskLayer = false;
      console.log(index);
      console.log(value);
      this.openBankList.forEach((req) => {
        // console.log(req);
        // console.log(this.linbankId);
        if (req.bankName == value) {
          // console.log(req.id);
          this.userData.openingBank = value;
          this.userData.openBankId = req.id;
          console.log(this.userData.openBankId);
        }
      });
    },
    onChange(picker, value, index) {
      // console.log(picker, value, index);
    },
    onCancel() {
      this.flags = false;
      this.maskLayer = false;
    },
    openBlank() {
      if (this.clidsjs) {
        console.log(111111);
        this.flags = true;
        this.maskLayer = true;
      }
    },
    // 认证
    register() {
      console.log(111);
      if (
        this.userData.name != "" &&
        this.userData.openingBank != "" &&
        this.userData.telephone != ""
      ) {
        var r = /^[0-9]+.?[0-9]*$/; //判断字符串是否为数字 //判断正整数 /^[1-9]+[0-9]*]*$/
        if (r.test(this.userData.bankCard) == false) {
          this.$notify({ type: "warning", message: "请输入规范的卡号" });

          return;
        }
        if (
          this.userData.bankCard.length < 16 ||
          this.userData.bankCard.length > 19
        ) {
          this.$notify({
            type: "warning",
            message: "银行卡号长度必须在16到19之间",
          });
          return;
        }

        var format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
        //号码规则校验
        if (!format.test(this.userData.identity)) {
          this.$notify({
            type: "warning",
            message: "身份证号码不合规",
          });
          return;
        }
        let that = this;
        this.$dialog
          .alert({
            title: "认证信息",
            message: "请确保信息无误！",
            beforeClose,
            showConfirmButton: true, //确认按钮
            showCancelButton: true, //取消按钮
          })
          .catch(() => {});
        function beforeClose(action, done) {
          if (action === "confirm") {
            // 确认按钮
            that.renzheng();
            done();
          } else {
            done();
          }
        }
      } else {
        this.$notify({
          type: "warning",
          message: "请填写完整内容",
        });
      }
    },
    // 认证
    renzheng() {
      realAuth({
        name: this.userData.name,
        idCard: this.userData.identity,
        bankCard: this.userData.bankCard,
        bankId: this.userData.openBankId,
        mobile: this.userData.telephone,
      }).then((res) => {
        console.log(res);
        if (res.code == "200") {
          if (res.data.isFaceRealAuth == true) {
            this.getFace();
          } else {
            this.$notify({
              type: "success",
              message: "认证成功",
            });
            this.$router.push("/home");
          }
        } else {
          this.$notify({
            type: "warning",
            message: res.message,
          });
        }
      });
    },

    // 刷脸认证
    getFace() {
      const domain = localStorage.getItem("domain");
      let lindomain = JSON.parse(domain);
      console.log(lindomain);
      getFaceIdentityUrl({
        callbackUrl: lindomain,
      }).then((res) => {
        console.log(res);
        // alert(JSON.stringify(res));
        if (res.code == "200" && res.message == "操作成功") {
          window.location.href = res.data;
        } else {
          this.$notify({
            type: "warning",
            message: res.message,
          });
        }
      });
    },
    goback() {
      this.$router.go(-1); //返回上一层
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  margin-top: 20%;
}
.wefx {
  position: absolute;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.5);
  width: 100%; /*宽度设置为100%，这样才能使隐藏背景层覆盖原页面*/
  height: 100%;
  filter: alpha(opacity=100); /*设置透明度为100%*/
  opacity: 1; /*非IE浏览器下设置透明度为100%*/
  display: block;
  z-index: 999;
}
.ingudn {
  position: fixed;
  width: 100%;
  height: 21rem;
  bottom: 0;
  z-index: 9999;
}
</style>>